@tailwind base;
@tailwind components;
@tailwind utilities;
@import "theme";

[v-cloak] {
    display: none;
}

.dataTables_wrapper {
    overflow: scroll;
}

.table-in-modal {
    max-height: 50vh;
    overflow: scroll;
}

hr {
    @apply tw-my-2;
}

.button-primary {
    @apply tw-bg-primary tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded;
    @apply tw-shadow-md tw-transition tw-duration-300 tw-ease-in-out;
    @apply hover:tw-bg-blue-700 hover:tw-shadow-lg;
}

.button-secondary {
    @apply tw-bg-secondary tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded;
    @apply tw-shadow-md tw-transition tw-duration-300 tw-ease-in-out;
    @apply hover:tw-bg-blue-900 hover:tw-shadow-lg;
}

.button-success {
    @apply tw-bg-success tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded;
    @apply tw-shadow-md tw-transition tw-duration-300 tw-ease-in-out;
    @apply hover:tw-bg-green-700 hover:tw-shadow-lg;
}

.button-warning {
    @apply tw-bg-warning tw-text-gray-800 tw-font-bold tw-py-2 tw-px-4 tw-rounded;
    @apply tw-shadow-md tw-transition tw-duration-300 tw-ease-in-out;
    @apply hover:tw-bg-yellow-600 hover:tw-shadow-lg;
}

.button-info {
    @apply tw-bg-info tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded;
    @apply tw-shadow-md tw-transition tw-duration-300 tw-ease-in-out;
    @apply hover:tw-bg-cyan-600 hover:tw-shadow-lg;
}

.button-danger {
    @apply tw-bg-danger tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded;
    @apply tw-shadow-md tw-transition tw-duration-300 tw-ease-in-out;
    @apply hover:tw-bg-red-700 hover:tw-shadow-lg;
}

.twinput-text {
    @apply tw-border tw-border-gray-300 tw-rounded tw-py-2 tw-px-4;
    @apply focus:tw-border-blue-500 focus:tw-ring focus:tw-ring-blue-200 focus:tw-outline-none;
}

.dataTables_wrapper {
    @apply tw-overflow-x-scroll;
}
